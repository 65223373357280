import type { SidebarEntry } from "zudoku";

export const sidebar: SidebarEntry = [
  {
    type: "category",
    label: "Getting Started",
    collapsed: false,
    collapsible: false,
    items: [
      {
        type: "doc",
        label: "Quick Start",
        id: "documentation/getting-started",
      },
    ],
  },
  {
    type: "category",
    label: "General Information",
    collapsed: false,
    collapsible: false,
    items: [
      "documentation/about-accuweather",
      "documentation/api-flow-diagram",
      "documentation/best-practices",
      "documentation/data-display-formats",
      "documentation/languages-localizations",
      "documentation/unit-types",
      "documentation/weather-icons",
      "documentation/weather-alarm-thresholds",
      "documentation/daily-index-info",
      "documentation/alerts-info",
      {
        type: "link",
        label: "System Status",
        href: "https://status.accuweather.com/",
      },
      "documentation/terms-of-use",
    ],
  },
  {
    type: "category",
    label: "Locations info",
    collapsed: false,
    collapsible: false,
    items: [
      "documentation/locations-info",
      "documentation/administrative-areas",
      "documentation/regions-and-countries",
      "documentation/point-of-interest-types",
      "documentation/autocomplete-search",
    ],
  },
  {
    type: "category",
    label: "Daily Index Info",
    collapsed: false,
    collapsible: false,
    items: [
      "documentation/available-daily-indices",
      "documentation/index-categories",
    ],
  },
];
