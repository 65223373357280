import { ZudokuConfig } from "zudoku";
import { ClientOnly } from "zudoku/components";
import { sidebar } from "./sidebar";
import { CheckoutPage } from "./src/checkout-page/CheckoutPage";
import { GettingStartedCallout } from "./src/GettingStartedCallout";
import { LandingPage } from "./src/LandingPage";
import { PricingPage } from "./src/pricing-page/PricingPage";
import { SubscriptionsPage } from "./src/subscriptions-page/SubscriptionsPage";
import { AccuweatherIdentityPlugin } from "./ApiIdentityPlugin";
import { ProfilePage } from "./src/profile-page/ProfilePage";
import { ChangeSubscriptionPage } from "./src/subscriptions-page/change-subscription-page/ChangeSubscriptionPage";

const config: ZudokuConfig = {
  page: {
    pageTitle: "",
    logo: {
      src: {
        light: "AccuWeatherLogoLight.svg",
        dark: "AccuWeatherLogoDark.svg",
      },
      width: "200px",
    },
  },
  metadata: {
    favicon:
      "https://developer.accuweather.com/sites/all/themes/accuweather/favicon.ico",
    title: "%s | AccuWeather Developer",
  },
  theme: {
    light: {
      primary: "22.1 89.7% 54.3%",
      secondary: "0 0 5%",
      secondaryForeground: "360 100% 100%",
      destructive: "0 64% 26%",
      muted: "210 20% 98%",
    },
    dark: {
      // background: "0 0% 6.67%",
      primary: "22.1 89.7% 54.3%",
      primaryForeground: "360 100% 100%",
      mutedForeground: "215 9.15% 66.66%",

      background: "210 5% 8%",
      foreground: "60 9.1% 97.8%",
      card: "20 14.3% 4.1%",
      // 'card-foreground': "60 9.1% 97.8%",
      popover: "20 14.3% 4.1%",
      // 'popover-foreground': "60 9.1% 97.8%",
      // 'primary': "20.5 90.2% 48.2%",
      // 'primary-foreground': "60 9.1% 97.8%",
      secondary: "12 6.5% 15.1%",
      // 'secondary-foreground': "60 9.1% 97.8%",
      muted: "12 6.5% 15.1%",
      // 'muted-foreground': "24 5.4% 63.9%",
      accent: "12 6.5% 15.1%",
      // 'accent-foreground': "60 9.1% 97.8%",
      destructive: "0 72.2% 50.6%",
      // 'destructive-foreground': "60 9.1% 97.8%",
      border: "12 6.5% 15.1%",
      input: "12 6.5% 15.1%",
      ring: "20.5 90.2% 48.2%",
      // 'chart'-1': 220 70% 50%",
      // 'chart'-2': 160 60% 45%",
      // 'chart'-3': 30 80% 55%",
      // 'chart'-4': 280 65% 60%",
      // 'chart'-5': 340 75% 55%",
    },
    fonts: {
      sans: {
        fontFamily: "Solis, sans-serif",
        url: "/fonts/fonts.css",
      },
    },
  },
  customPages: [
    { path: "/home", element: <LandingPage /> },
    {
      path: "/pricing",
      element: (
        <ClientOnly>
          <PricingPage />
        </ClientOnly>
      ),
    },
    {
      path: "/checkout",
      render: CheckoutPage,
      prose: false,
    },
    { path: "/subscriptions", render: SubscriptionsPage },
    { path: "/profile", render: ProfilePage },
    {
      path: "/subscriptions/:subscription",
      render: ChangeSubscriptionPage,
    },
  ],
  authentication: {
    type: "auth0",
    domain: "auth.zuplo.io",
    clientId: "kWQs12Q9Og4w6zzI82qJSa3klN1sMtvz",
    audience: "https://api.example.com/",
    redirectToAfterSignIn: "/subscriptions",
    redirectToAfterSignUp: "/subscriptions",
  },
  UNSAFE_slotlets: {
    "api-keys-list-page": <GettingStartedCallout />,
  },
  topNavigation: [
    { label: "Home", id: "home" },
    { label: "Documentation", id: "documentation" },
    { label: "Core Weather API", id: "core-weather" },
    { label: "Minute Cast API", id: "minute-cast" },
    { label: "Pricing", id: "pricing" },
    { label: "Subscriptions & Keys", id: "subscriptions", display: "auth" },
  ],
  sidebar: { documentation: sidebar },
  redirects: [
    { from: "/", to: "/home" },
    { from: "/documentation", to: "/documentation/getting-started" },
  ],
  plugins: [new AccuweatherIdentityPlugin()],
  apis: [
    {
      type: "file",
      input: "../config/core-weather.oas.json",
      navigationId: "core-weather",
    },
    {
      type: "file",
      input: "../config/minutecast.oas.json",
      navigationId: "minute-cast",
    },
  ],
  docs: {
    files: "/pages/**/*.mdx",
  },
};

export default config;
